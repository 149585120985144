import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../components/Core/authConfig";
import { Title } from "../components/ui/Title";

import "./Home.css";
import { Dashboard } from "./Dashboard";
import { Checkbox } from "../components/ui/Checkbox";

export const Home = (props:any) => {
  const [userAccepted, setUserAccepted] = useState(false);
  const { instance } = useMsal();
  
  const HhandleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <div className="App">
        <AuthenticatedTemplate>
          <Dashboard />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {newHomeUnAuthentic()}
        </UnauthenticatedTemplate>
      </div>
    </>
  );

  function newHomeUnAuthentic() {
    return (
      <>
        <Title className="home__title">Sign in to CPRD Safe</Title>
        <p className="home__copy">To use this service, you'll need to sign in using your CPRD Safe user ID and password.</p>
        <p>CPRD Safe is a Trusted Research Environment for secure research.</p>
        <p>User guidance is available via <a href="https://www.cprd.com/cprd-safe-features-guide-users" target="_blank" rel="noreferrer">CPRD Safe | CPRD</a></p>
        <p className="home__agreement">CPRD Safe is a private computing environment and system that CPRD controls and provides Authorised Users with access to as part of our standard business activities. You must not attempt to access CPRD Safe unless you are an Authorised User. By accessing and using CPRD Safe, you acknowledge and understand that your use of CPRD Safe may be monitored and, if applicable, that CPRD reserves the right to access your CPRD Safe Workspace (as defined by your organisation’s CPRD Safe Licence Agreement), in each case, for certain specified purposes and in accordance with applicable law and relevant CPRD policies and procedures. By accessing CPRD Safe you confirm that you have read and agree to the terms of the <a className="home__ua-link" href="https://www.cprd.com/cprd-safe-end-user-access-agreement" target="_blank" rel="noreferrer">CPRD Safe End User Access Agreement (EUAA)</a>.</p>
        <Checkbox
          label="I accept"
          onChange={() => setUserAccepted(!userAccepted)}
        />
        <button disabled={!userAccepted} className="govuk-button home__signin-button" onClick={() => HhandleLogin()}>Sign in to CPRD Safe</button>
      </>
    )
  }    
}
