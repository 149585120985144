import { useContext, useEffect, useRef, useState } from 'react';
import { ResourceVM, Properties } from '../models/resource';
import { error } from '../../types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, LabelText, HintText, Input, ErrorText, ErrorSummary } from 'govuk-react';

import { FormWrapper } from '../ui/FormWrapper';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { CancelButton } from '../ui/CancelButton';
import { FormLabel } from '../ui/FormLabel';
import { Textarea } from '../ui/Textarea';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

{/*https://bobbyhadz.com/blog/react-unable-to-type-in-input */}
export const CreateVMForm= (props:any) => {
  const [errors, setErrors] = useState<null | error[]>(null);
  const [templateType, setTemplateType] = useState('');
  const [imageName, setImageName] = useState<string | undefined>('');
  const workspaceCtx = useContext(WorkspaceContext);
  const templateNameInputRef = useRef<HTMLInputElement>(null);
  const vmNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);        
  const imageNameInputRef = useRef<HTMLInputElement>(null);
  let {id} = useParams();
  let navigate = useNavigate();

  const checkTemplateType = (name: string) => {
    if (name === "tre-workspace-msl") {
      setTemplateType("tre-service-guacamole-windowsvm-msl");
      setImageName(process.env.REACT_APP_VM_MSL_IMAGE_NAME);
    } else if (name === "tre-workspace-rdg") {
      setTemplateType("tre-service-guacamole-windowsvm-rdg");
      setImageName(process.env.REACT_APP_VM_RDG_IMAGE_NAME);
    } else {
      setTemplateType("tre-service-guacamole-windowsvm-ssl");
      setImageName(process.env.REACT_APP_VM_SSL_IMAGE_NAME);
    }
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && (
      checkTemplateType(workspaceCtx.workspace.templateName)
    )
  }, [workspaceCtx]);

  const routeChange = () =>{ 
    let path = `/workspaces/` + id; 
    navigate(path);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const createVMHandler = (event:any) => {
    event.preventDefault();
    setErrors(null);
    const newErrors = [];

    const enteredtemplateName = templateNameInputRef.current!.value;
    const entereddescription = descriptionInputRef.current!.value;
    const enteredvmName = vmNameInputRef.current!.value;
    const enteredimageName = imageNameInputRef.current!.value;

    if (!enteredvmName || (enteredvmName.length < 5 || enteredvmName.length > 100)) {
      newErrors.push({
        targetName: "vmname",
        text: "Enter a valid VM name, mimimun of 5 characters and maximum of 100"
      })
    }

    if (entereddescription && (entereddescription.length < 10 || entereddescription.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, minimum of 10 chracters and maximum of 100"
      })
    }
    
    const properties : Properties = {
      display_name: enteredvmName,
      description: entereddescription,
      os_image: enteredimageName
    };

    const newResourceVMRequest : ResourceVM = {
      templateName: enteredtemplateName,                
      properties: properties
    };
    
    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : props.onAddImportRequest(newResourceVMRequest);        
  };

  return (
    <>
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={createVMHandler}>
        <FormWrapper>
          <FormLabel>
            <LabelText htmlFor="templatename" className="govuk-label govuk-label--4">Template name</LabelText>
            <HintText id="emailHelp" className="form-text text-muted">Template name for the VM, which contains all configuration settings.</HintText>
            <Input id="templatename" ref={templateNameInputRef} defaultValue={templateType} readOnly />   
          </FormLabel>

          <FormLabel error={hasError("vmname")} id="vmname">
            <LabelText className="govuk-label govuk-label--4">VM name</LabelText>
            <HintText id="emailHelp" className="form-text text-muted">The name of the VM.</HintText>
            {hasError("vmname") && <ErrorText>Enter a valid VM name (maximum 100 characters)</ErrorText>}
            <Input ref={vmNameInputRef} />       
          </FormLabel>
            
          <FormLabel error={hasError("description")} id="description">
            <LabelText htmlFor="description">Description</LabelText>
            <HintText>Description for the new VM.</HintText>
            {hasError("description") && <ErrorText>Enter a valid description (maximum 100 characters)</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} />
          </FormLabel>

          <FormLabel>
            <LabelText htmlFor="imagename" className="govuk-label govuk-label--4">Image name</LabelText>
            <Input id="imagename" ref={imageNameInputRef} defaultValue={imageName} readOnly />
          </FormLabel>

          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Save and continue</Button>
            <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </>
  );
}
