import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import yn from "yn";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import {CustomMessageCard, CustomMessageErrorCard, CustomMessageSuccessCard } from "../Error/MessageCard";

import { CreateVMForm } from "./CreateVMForm";
import { ResourceVM } from "../models/resource";
import { ApiEndpoint } from "../models/apiEndPoints";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { AppRolesContext } from "../../contexts/AppRolesContext";

import { Title } from "../ui/Title";

export const CreateVM=(props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [responseData, setresponseData] = useState(null);
  const [newResourceVMData, setnewResourceVMData] = useState<ResourceVM>({} as ResourceVM);
  const [errorData, setErrorData] = useState(Error);
  const [isRequestValid, setRequestValid] = useState(false);
  const workspaceCtx = useContext(WorkspaceContext);

  let paramIds = useParams();

  let finalurl=`${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${paramIds.id}/${ApiEndpoint.WorkspaceServices}/${paramIds.rid}/${ApiEndpoint.UserResources}`;    
    
  const VMHandler = useCallback(async (newResourcVMRequest:any) => {
    setErrorData({} as Error);
    setnewResourceVMData(newResourcVMRequest);

    if ((newResourcVMRequest.properties.display_name?.length > 0)) {
      if(workspaceCtx.workspace.properties!==undefined && workspaceCtx.workspace.id == paramIds.id) {
        setIsLoading(true);
        yn(process.env.REACT_APP_DEBUG) && console.log('Trying to submit the request...');
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
          scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
        }).then(async (response) => {
        await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Post,newResourcVMRequest).then(response => setresponseData(response))
          .catch((err:any)=> setErrorData(err));
              setIsLoading(false);
          })
          .catch((err:any) => {
            setErrorData(err);
            setIsLoading(false);                
          })           
        yn(process.env.REACT_APP_DEBUG) && console.log('New request submitted...');
      }
    } else {
      console.log('VM name: length is smaller then expected...')
    }
      
  },[setnewResourceVMData, accounts, finalurl, instance, isRequestValid, paramIds.id, workspaceCtx]);

  useEffect(() => {
    newResourceVMData.properties ?
    setRequestValid((newResourceVMData.properties.display_name?.length > 0)):<></>
  }, [newResourceVMData,setRequestValid]);

  let rtnUrl = `workspaces/${paramIds.id}/workspace-services/${paramIds.rid}/user-resources`;
  let msgprops = {
    msgData:'New VM request posted for Creation and now in queued and pending, please go to resource area to view the progress...',
    linkData:rtnUrl,
    linkMsg: "Return to List of VMs"
  }

  return (
    responseData ? (
      <>
        <Title>Create a new VM</Title>
        {responseData ? <CustomMessageSuccessCard props={msgprops}></CustomMessageSuccessCard>:
        <CustomMessageCard msgData={errorData}></CustomMessageCard> }
      </>
    ) : (
      <>
        <Title>Create a new VM</Title>
        {!isLoading && <CreateVMForm onAddImportRequest={VMHandler} />}
        {(errorData.message === undefined || errorData === undefined || errorData.message === '') ? (
          null
          ) : (
            <CustomMessageErrorCard props={msgprops={msgData:errorData.message,linkData:rtnUrl,linkMsg:"Return to List of VMs"}} />
          )
        }
      </>
    )
  );
}
